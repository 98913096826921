export const testimonialsdetails = [
  {
    "id": 1,
    "image": "../../assets/img/testimonials/testimonials-1.jpg",
    "author": "Michael Amster",
    "position": "VP, Software Engineering",
    "reference": "I have both directly and indirectly managed Jose during my 4.5 years at Verifi where we overlapped. I have to say that Jose always brought dedication to his work. He would focus in details whether it was our evaluation of Kong for App Gateway or working through the 1000s of PHP remediations we had to do after joining Visa. While bringing focus, he also deeply cared for his team. He would organize tech book readings, brown bag lunches, board game lunches. He was the only manager in my team to consistently take his team out to lunch to build rapport. He has great team building skills and creates great team loyalty. Over the past 4 years Jose has led some of our most difficult projects and while it was not always easy or perfect, he never gave up and put in incredible hours to support his team through to success which culminated in the completion of our migration into Visa. I would recommend Jose to anyone looking for a leader who cares and works with his team side by side."
  },
  {
    "id": 2,
    "image": "../../assets/img/testimonials/testimonials-2.jpg",
    "author": "Kevin Lee",
    "position": "Site Reliability Engineer<",
    "reference": "Thank you for your crucial help with the one of the most key pieces of the migration away from Legacy Verifi. Thank you for demonstrating what an exceptional leader is/does and setting the right tone (attitude). During the challenges of the Project Nile migration, I admired from (mostly afar) how you kept the team together while all working long, ridiculous hours. Especially remember on an Amster TTM you sharing the team holiday party that you had right in the midst of one of the most challenging periods of that. And you also always took on the long-running and perhaps thankless projects that many others did not (the Clover SSO/PCI audits)"
  },
  {
    "id": 3,
    "image": "../../assets/img/testimonials/testimonials-3.jpg",
    "author": "Charles Le",
    "position": "Director, Software Engineering",
    "reference": "Jose is caring, diligent and meticulous about his work. I've had the pleasure of seeing him grow in his career from an individual contributor to leading and managing a team. We first hired him as a contractor. When he left 5 years later, he was managing one of the most important teams of the company. He also had a great relationship with our offshore team, which is valuable because that can often go in the wrong direction if left unchecked. As an engineer, he was able to streamline a few key processes that reduced a lot of manual processing and errors. Unfortunately, I can't share details of the work. As a manager, he showed extreme care for his reports and was a great mentor. He always pushed his team to grow personally and professionally. I could see the great relationships he had with his reports. You only get this if you really care. As a colleague, Jose never left me in a position where I had to worry. If something needed to be done, I trusted that Jose would take care of it. That's extremely valuable in running the business!"
  },
  {
    "id": 4,
    "image": "../../assets/img/testimonials/testimonials-4.jpg",
    "author": "James Chang",
    "position": "Lead Software Engineer",
    "reference": "...it was a pleasure to work with [Jose]. I think a lot about how [Jose] genuinely sought to connect with [his] reports, whether it was by getting to know them at lunch outings or over board games; [He] really understands the importance of seeing the human side of [his] coworkers. If my career continues down the path of management, I hope I can emulate that practice someday!"
  },
  {
    "id": 5,
    "image": "../../assets/img/testimonials/testimonials-5.jpg",
    "author": "Tom Harris",
    "position": "Principle Software Engineer",
    "reference": "I am pleased to write a strong recommendation for Jose, who was an outstanding Java developer on our team when we worked together at Dun & Bradstreet Credibility. Jose consistently demonstrated a deep understanding of Java programming and made significant contributions to the projects. His incredibly strong problem-solving skills and ability to write clean, efficient code was instrumental in the success of our team. In addition to technical expertise, Jose communicates effectively, collaborates well with team members, and always meets deadlines. I highly recommend Jose for any Java development position and am confident that he will be a valuable asset to any organization."
  }
];