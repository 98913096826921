import '../assets/css/main.css';
import profilepic from '../assets/img/my-profile-img.jpg';

function About() {

    return (
        <section id="about" className="about section">

            <div className="container section-title" data-aos="fade-up">
                <h2>About</h2>
                <p>Hello! I'm Jose. I am software engineer at heart with a passion in leading projects and mentoring
                    others. Among my many interests are playing and composing orchestral baroque works, playing board
                    games, flying my flight simulator, reading books on Sherlock Holmes and by Michael Crichton and
                    watching/reading Anime.</p>
            </div>

            <div className="container" data-aos="fade-up" data-aos-delay="100">

                <div className="row gy-4 justify-content-center">
                    <div className="col-lg-4">
                        <img src={profilepic} className="img-fluid" alt=""/>
                    </div>
                    <div className="col-lg-8 content">
                        <h2>Technology Leader and Software Engineer specializing in the Java Technology Stack</h2>
                        <p className="fst-italic py-3">
                            Here are some of my personal details for your information. Please feel free to read further
                            to go over specifics regarding my resume, work experience, key highlights, and testimonials.
                        </p>
                        <div className="row">
                            <div className="col-lg-6">
                                <ul>
                                    <li><i className="bi bi-chevron-right"></i> <strong>Website:</strong>
                                        <span>www.bravaldi.com</span></li>
                                    <li><i className="bi bi-chevron-right"></i> <strong>Phone:</strong> <span>323 496 9047</span>
                                    </li>
                                    <li><i className="bi bi-chevron-right"></i> <strong>City:</strong> <span>Greater Los Angeles, CA, USA</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-6">
                                <ul>
                                    <li><i className="bi bi-chevron-right"></i> <strong>Degree:</strong> <span>B.S. in Computer Information Systems</span>
                                    </li>
                                    <li><i className="bi bi-chevron-right"></i> <strong>Email:</strong>
                                        <span>jose_bravo@hotmail.com</span></li>
                                    <li><i className="bi bi-chevron-right"></i> <strong>Freelance:</strong>
                                        <span>Available</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </section>
    );
}

export default About;