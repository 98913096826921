import { useState } from "react";
import '../assets/css/main.css';

function LeftNav() {

    let [sidebar, setSideBar] = useState(false);

    function toggleLeftNav() {
        setSideBar(!sidebar);
    }

    return (
      <header id="header"
              className={sidebar
                  ? "header dark-background d-flex flex-column header-show"
                  : "header dark-background d-flex flex-column "}>

        <i onClick={() => toggleLeftNav()}
                className={sidebar
                    ? "header-toggle d-xl-none bi bi-x"
                    : "header-toggle d-xl-none bi bi-list"}></i>

        <div className="profile-img">
          <img src="assets/img/my-profile-img.jpg" alt="" className="img-fluid rounded-circle"/>
        </div>

        <a href="index.html" className="logo d-flex align-items-center justify-content-center">
          <h1 className="sitename">Jose Bravo-Tejeda</h1>
        </a>

        <div className="social-links text-center">
          <a href="https://x.com/bravaldi" className="twitter" target="_blank"><i className="bi bi-twitter-x"></i></a>
          <a href="https://www.facebook.com/bravaldi" className="facebook" target="_blank"><i
              className="bi bi-facebook"></i></a>
          <a href="https://www.linkedin.com/in/josebravotejeda" className="linkedin" target="_blank"><i
              className="bi bi-linkedin"></i>/</a>
        </div>

        <nav id="navmenu" className="navmenu">
            <ul>
                <li><a href="#hero" onClick={() => toggleLeftNav()} className="active"><i className="bi bi-house navicon"></i>Home</a></li>
                <li><a href="#about" onClick={() => toggleLeftNav()}><i className="bi bi-person navicon"></i> About</a></li>
                <li><a href="#services" onClick={() => toggleLeftNav()}><i className="bi bi-hdd-stack navicon"></i> Key Highlights</a></li>
                <li><a href="#resume" onClick={() => toggleLeftNav()}><i className="bi bi-file-earmark-text navicon"></i> Resume</a></li>
                <li><a href="#portfolio" onClick={() => toggleLeftNav()}><i className="bi bi-images navicon"></i> Work Experiences</a></li>
                <li><a href="#testimonials" onClick={() => toggleLeftNav()}><i className="bi bi-person navicon"></i> Testimonials</a></li>
                <li><a href="#contact" onClick={() => toggleLeftNav()}><i className="bi bi-envelope navicon"></i> Contact</a></li>
            </ul>
        </nav>

      </header>
    );
}

export default LeftNav;
