import '../assets/css/main.css';

function KeyHighlights() {

    return (
        <section id="services" className="services section">

            <div className="container section-title" data-aos="fade-up">
                <h2>Key Highlights</h2>
                <p></p>
            </div>
            <div className="container">
                <div className="row gy-4">
                    <div className="col-lg-4 col-md-6 service-item d-flex" data-aos="fade-up" data-aos-delay="100">
                        <div className="icon flex-shrink-0"><i className="bi bi-briefcase"></i></div>
                        <div>
                            <h4 className="title">Proven Leadership</h4>
                            <p className="description">Proven leadership experience working with both on-shore and offshore teams</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 service-item d-flex" data-aos="fade-up" data-aos-delay="200">
                        <div className="icon flex-shrink-0"><i className="bi bi-people"></i></div>
                        <div>
                            <h4 className="title"><span className="stretched-link">Mentorship Focused</span></h4>
                            <p className="description">Ability to mentor engineers with varying levels of educational, work experience, and situations</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 service-item d-flex" data-aos="fade-up" data-aos-delay="300">
                        <div className="icon flex-shrink-0"><i className="bi bi-calendar4-week"></i></div>
                        <div>
                            <h4 className="title">Software Product... delivered.</h4>
                            <p className="description">Work with teams to get deliverables completed on schedule despite conflicting priorities</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 service-item d-flex" data-aos="fade-up" data-aos-delay="400">
                        <div className="icon flex-shrink-0"><i className="bi bi-binoculars"></i></div>
                        <div>
                            <h4 className="title">Focused Communications</h4>
                            <p className="description">Effective communication skills in coordinating efforts across different teams</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 service-item d-flex" data-aos="fade-up" data-aos-delay="500">
                        <div className="icon flex-shrink-0"><i className="bi bi-card-checklist"></i></div>
                        <div>
                            <h4 className="title">Experience where it's needed</h4>
                            <p className="description">Contributed major development efforts across several large projects as both a leader and a contributor</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 service-item d-flex" data-aos="fade-up" data-aos-delay="600">
                        <div className="icon flex-shrink-0"><i className="bi bi-arrows"></i></div>
                        <div>
                            <h4 className="title">Migration Experience</h4>
                            <p className="description">Experience working with large-scale technology migration initiatives</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default KeyHighlights;