import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import '../assets/css/main.css';
import { testimonialsdetails } from "./data/testimonialsdetails";

function Testimonials() {

    const testimonialsList = testimonialsdetails.map((testimonialdetail) =>
        <SwiperSlide>
            <div className="testimonial-item">
                <p>
                    <i className="bi bi-quote quote-icon-left"></i>
                    <span>{testimonialdetail.reference}</span>
                    <i className="bi bi-quote quote-icon-right"></i>
                </p>
                <img src={testimonialdetail.image} className="testimonial-img" alt=""/>
                <h3>{testimonialdetail.author}</h3>
                <h4>{testimonialdetail.position}</h4>
            </div>
        </SwiperSlide>
    );

    return (
        <section id="testimonials" className="testimonials section light-background">
            <div className="container section-title" data-aos="fade-up">
                <h2>Testimonials</h2>
                <p>Some humbling feedback I have received over the years, including from my supervisors, peers, and direct reports:</p>
            </div>
            <div className="container" data-aos="fade-up" data-aos-delay="100">
                <Swiper
                    slidesPerView={2}
                    spaceBetween={30}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Autoplay, Pagination]}
                    className="mySwiper"
                    autoplay={{
                        delay: 15000,
                        disableOnInteraction: false,
                    }}>
                    {testimonialsList}
                </Swiper>
            </div>
        </section>
    );
}

export default Testimonials;