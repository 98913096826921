import '../assets/css/main.css';

function Resume() {

    return (
        <section id="resume" className="resume section">

            <div className="container section-title" data-aos="fade-up">
                <h2>Resume</h2>
                <p>Highlights of my last 7 years of professional experience along with my educational background. Please
                    download my resume PDF for more detailed information regarding my work experience and additional
                    details.</p>
            </div>

            <div className="container">

                <div className="row">

                    <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
                        <h3 className="resume-title">Summary</h3>

                        <div className="resume-item pb-0">
                            <h4>Jose Bravo-Tejeda</h4>
                            <p><em>Information Technology professional looking to lead development efforts in your
                                organization. I am an experienced manager with deep experience in Java/Spring development, working in
                                secure financial environments, building successful teams both locally and abroad. Takes great interest in mentoring
                                developers in their professional development. Forward-thinking, inquisitive mindset on how to improve
                                existing platform implementations. Loves coding!</em></p>
                            <ul>
                                <li>Greater Los Angeles County (open to In-Office, Hybrid, or Remote)</li>
                                <li>323 496 9047</li>
                                <li>jose_bravo@hotmail.com</li>
                            </ul>
                        </div>

                        <h3 className="resume-title">Professional Experience</h3>


                        <div className="resume-item">
                            <h4>Senior Software Engineering Manager</h4>
                            <h5>2018 - 2024</h5>
                            <p><em>Visa, Inc. / Verifi Inc. (business acquired), Los Angeles, CA </em></p>
                            <ul>
                                <li>
                                    Oversee the day-to-day management of the OI and CBR teams. Led these teams to deliver key deliverables while addressing on-going, operational requirements. Delivering these features allowed the organization to remain competitive in our business space while ensuring that operational needs were met. We were able to meet our time commitment to the business 90% of the time ensuring that software deliverables are ready to market as promised.
                                </li>
                                <li>
                                    Collaborated with the team on knowledge transfer activities as we migrated Verifi application responsibilities from contractors to Visa FTEs as part of the Verifi-to-Visa migration initiative. This consisted of a complete platform rewrite from PHP/Play to Spring/Clover DX frameworks. This migration was a must-win situation as the business was required to migrate to Visa platforms to continue to do business after acquisition. All 100% of intended services were either migrated or rewritten and are now hosted on Visa platforms ensuring that all Verifi platforms are in platform compliance with Visa technology standards.
                                </li>
                                <li>
                                    Led the Cyber Security remediation process for the legacy Verifi platform as part of the Verifi-to-Visa Data technology migration initiative. This meant organizing 15K security findings, hiring contractor staff, and leading remediation efforts over a period of 3 years. This allowed us to remain compliant with Cybersecurity as we focused our efforts on addressing the most urgent security items that could impact the organization. Due to our efforts, we suffered 0 security breaches on this code as the team aggressively patched up security day findings, including zero-day security vulnerabilities until all the code was decommissioned. We also met our Required Remediation Date requirements 95% of the time.
                                </li>
                                <li>
                                    Managed the day-to-day operations of all services under these platforms. This encompasses Cybersecurity initiatives, migration initiatives, audits, and attestations. Covering these responsibilities allowed my team to focus 90% of their efforts on addressing business requirements, allowing us to meet commitments on schedule.
                                </li>
                                <li>
                                    Worked to stand up an engineering team in India to assist with development efforts. This encompassed hiring staff, setting up meets between U.S. and India-based teams to assist with knowledge transfer, setting milestones to ensure that expectations were being met, and worked with Product/Project Mgmt. to setup Jira projects, backlog, and priorities for the team. This ultimately increased my headcount by 120%, giving us substantially more resources to address business, operational, and security requirements.
                                </li>
                                <li>
                                    Invested efforts in ensuring that my direct reports were adequately mentored. With effort, 3 direct reports promoted to senior roles despite intense competition. Supervised a Technical Product Manager that was having performance issues (and was under a PIP). With effort, we were able to successfully lead the employee out of the plan and is now finding success in the role. These efforts encouraged strong morale resulting in high satisfaction and reduced turnover rates among my staff.
                                </li>
                                <li>
                                    Led initiatives targeted at encouraging positive morale in my teams. Organized monthly lunch meets out of the office to encourage comradeship. Had meets every quarter to highlight key wins that occurred in the last quarter, and organized offsite events, etc. These activities promoted high morale and team camaraderie, which is important for teams that work long hours on difficult assignments.
                                </li>
                                <li>
                                    Participated in design/architecture discussions, peer reviews, and held platform modernization meetings to go over how we want to continue to modernize our platform. Coded on various projects and platforms on a variety of programming platforms such as Java, Python, Lua, and others.
                                </li>
                            </ul>
                            <br/>
                            <a href="jose-bravo-resume.pdf" target="_blank">Download my resume here to view additional
                                details regarding my work experience.</a>
                        </div>
                    </div>

                    <div className="col-lg-6" data-aos="fade-up" data-aos-delay="200">
                        <h3 className="resume-title">Education</h3>
                        <div className="resume-item">
                            <h4>Supplemental Courses in Leadership</h4>
                            <h5>2024</h5>
                            <p><em>College of the Canyons, Valencia, CA</em></p>
                            <p>Taking additional supplemental courses to further build on my leadership skills in
                                software engineering. These courses include:
                                <br/>
                                <br/>
                                <ul>
                                    <li>Successful Managing and Developing People</li>
                                    <li>Critical Thinking / Problem Solving</li>
                                    <li>Emerging Leaders</li>
                                    <li>Negotiating</li>
                                </ul>
                            </p>
                        </div>
                        <div className="resume-item">
                            <h4>UCLA Extension - Technical Management Program</h4>
                            <h5>2019</h5>
                            <p><em>University of California, Los Angeles</em></p>
                            <p>I was selected by my employer to attend this program at UCLA considering the
                                contributions I
                                delivered and the opportunity that this program can further refine my skills as a
                                leader.
                                Courses
                                included:
                                <br/>
                                <br/>
                                <ul>
                                    <li>Adaptive Project Management</li>
                                    <li>Creating and Sustaining Excellence in the Workplace</li>
                                    <li>Continue to Succeed as a Leader</li>
                                    <li>People, Power, and Projects: Managing the Human Dynamic</li>
                                </ul>
                            </p>
                        </div>
                        <div className="resume-item">
                            <h4>Bachelor of Science, Computer Information Systems</h4>
                            <h5>2010</h5>
                            <p><em>California State University, Los Angeles</em></p>
                            <p>Received my undergraduate degree in Computer information Systems</p>
                        </div>

                        <div className="resume-item">
                            <h4>Associate of Arts, Liberal Arts</h4>
                            <h5>2006</h5>
                            <p><em>East Los Angeles College</em></p>
                            <p>Received my associates degree in Liberal Arts. Additionally, I was able to apply my
                                college
                                credits towards my undergraduate degree.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Resume;