import React, { useState } from 'react';
import '../assets/css/main.css';
import validator from "validator/es";

function Contact() {

    let [name, setName] = useState("");
    let [email, setEmail] = useState("");
    let [subject, setSubject] = useState("");
    let [message, setMessage] = useState("");
    let [buttonToggle, setButtonToggle] = useState("inline-block");
    let [loadingDivDisplay, setLoadingDivDisplay] = useState("none");
    let [successDivDisplay, setSuccessDivDisplay] = useState("none");
    let [errorDivDisplay, setErrorDivDisplay] = useState("none");
    let [emailErrorDisplay, setEmailErrorDisplay] = useState("none");

    function setEmailField(emailFormValue) {
        setEmail(email = emailFormValue);
        setEmailErrorDisplay(emailErrorDisplay = "none");
    }

    async function sendMessage() {

        if (!validator.isEmail(email)) {
            setEmailErrorDisplay(errorDivDisplay = "inline-block");
            setButtonToggle(buttonToggle = "none");
            setErrorDivDisplay(errorDivDisplay = "block");

            setTimeout(() => {
                setErrorDivDisplay(successDivDisplay = "none");
                setButtonToggle(buttonToggle = "inline-block");
            }, 5000)

            return;
        }


        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify
                ({
                name: name,
                email: email,
                subject: subject,
                message: message
                })
        };

        setLoadingDivDisplay(loadingDivDisplay = "block");
        setButtonToggle(buttonToggle = "none")

        fetch(process.env.REACT_APP_MESSAGE_CONTROLLER_URL, requestOptions)
            .then(response => {
                if (response.ok) {
                    setLoadingDivDisplay(loadingDivDisplay = "none");
                    setSuccessDivDisplay(successDivDisplay = "block")

                    setName("");
                    setEmail("");
                    setSubject("");
                    setMessage("");

                    setTimeout(() => {
                        setSuccessDivDisplay(successDivDisplay = "none")
                        setButtonToggle(buttonToggle = "inline-block")
                    }, 5000)

                }  else {
                    setLoadingDivDisplay(loadingDivDisplay = "none");
                    setErrorDivDisplay(errorDivDisplay = "block")

                    setTimeout(() => {
                        setErrorDivDisplay(successDivDisplay = "none")
                        setButtonToggle(buttonToggle = "inline-block")
                    }, 5000)
                }
            })
            .catch(err => {
                console.log(err.toString());
                setLoadingDivDisplay(loadingDivDisplay = "none");
                setErrorDivDisplay(errorDivDisplay = "block")

                setTimeout(() => {
                    setErrorDivDisplay(successDivDisplay = "none")
                    setButtonToggle(buttonToggle = "inline-block")
                }, 5000)
            })
    }

        return (
        <section id="contact" className="contact section">

            <div className="container section-title" data-aos="fade-up">
                <h2>Contact</h2>
                <p>Please send me an email, reach out on LinkedIn/X/Facebook, or use the supplied form to reach out to me. Thanks!</p>
            </div>

            <div className="container" data-aos="fade-up" data-aos-delay="100">

                <div className="row gy-4">

                    <div className="col-lg-5">

                        <div className="info-wrap">
                            <div className="info-item d-flex" data-aos="fade-up" data-aos-delay="200">
                                <i className="bi bi-geo-alt flex-shrink-0"></i>
                                <div>
                                    <h3>Location</h3>
                                    <p>Greater Los Angeles County</p>
                                </div>
                            </div>

                            <div className="info-item d-flex" data-aos="fade-up" data-aos-delay="300">
                                <i className="bi bi-telephone flex-shrink-0"></i>
                                <div>
                                    <h3>Call Me</h3>
                                    <p>323 496 9047</p>
                                </div>
                            </div>

                            <div className="info-item d-flex" data-aos="fade-up" data-aos-delay="400">
                                <i className="bi bi-envelope flex-shrink-0"></i>
                                <div>
                                    <h3>Email Me</h3>
                                    <p>jose_bravo at hotmail.com</p>
                                </div>
                            </div>

                            <iframe src="https://www.google.com/maps/embed/v1/place?q=Los+Angeles,+CA,+USA&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8" frameBorder="0"
                                    style={{border: '0', width: '100%', height: '270px'}} allowFullScreen="" loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>

                    <div className="col-lg-7">
                        <div className="php-email-form" data-aos="fade-up" data-aos-delay="200">
                            <div className="row gy-4">

                                <div className="col-md-6">
                                    <label htmlFor="name-field" className="pb-2">Your Name</label>
                                    <input type="text" name="name" id="name-field" value={name} onChange={(event) => {setName(event.target.value)}} className="form-control" required=""/>
                                </div>

                                <div className="col-md-6">
                                    <label htmlFor="email-field" className="pb-2">Your Email</label>
                                    <label htmlFor="email-invalid">
                                        <span id="email-invalid" style={{color: "red", display: `${emailErrorDisplay}`}}>&nbsp;&nbsp;&nbsp;&nbsp;Invalid Email Address</span>
                                    </label>
                                    <input type="email" className="form-control" name="email" id="email-field" value={email} onChange={(event) => {
                                        setEmailField(event.target.value)
                                    }} required=""/>
                                </div>

                                <div className="col-md-12">
                                    <label htmlFor="subject-field" className="pb-2">Subject</label>
                                    <input type="text" className="form-control" name="subject" id="subject-field" value={subject} onChange={(event) => {setSubject(event.target.value)}} required=""/>
                                </div>

                                <div className="col-md-12">
                                    <label htmlFor="message-field" className="pb-2">Message</label>
                                    <textarea className="form-control" name="message" rows="10" id="message-field" value={message} onChange={(event) => {setMessage(event.target.value)}} required=""></textarea>
                                </div>

                                <div className="col-md-12 text-center">
                                    <div className="loading" style={{display: `${loadingDivDisplay}`}}>Loading</div>
                                    <div className="error-message" style={{display: `${errorDivDisplay}`}}>An error occurred when attempting to process your request.</div>
                                    <div className="sent-message" style={{display: `${successDivDisplay}`}}>Your message has been sent. Thank you!</div>

                                    <button onClick={() => sendMessage()} style={
                                        {
                                            color: 'var(--contrast-color)',
                                            background: 'var(--accent-color)',
                                            border: 0,
                                            padding: '10px 30px',
                                            borderRadius: '50px',
                                            display: `${buttonToggle}`
                                        }}>
                                        Send Message
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        );
    }

export default Contact;