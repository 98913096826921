import React, { useState } from 'react';
import { workexperiencesdetails } from "./data/workexperiencesdetails";
import '../assets/css/main.css';
import closeimg from "../assets/img/close-chevron.svg";

function WorkExperiences() {
    let [showExperienceDetailPanel, setShowExperienceDetailPanel] = useState("none");
    let [workDetail, setWorkDetail] = useState({});

    function updateWorkExperienceDetail (prop) {
        if (prop !== 0 ) {
            setWorkDetail(workexperiencesdetails.filter(workexperiencesdetail =>  workexperiencesdetail.id === prop)[0]);
        }
        setShowExperienceDetailPanel(prop === 0 ? showExperienceDetailPanel = "none" : showExperienceDetailPanel = "block");
    }

    const experienceList= workexperiencesdetails.map((experience) =>
        <div className="col-lg-4 col-md-6 portfolio-item isotope-item filter-app">
            <div className="portfolio-content h-100">
                <img src={experience.image} className="img-fluid" alt=""/>
                <div className="portfolio-info">
                    <h4>App 1</h4>
                    <p>{experience.url} ({experience.employer})</p>
                    <a href="#portfolio-details" onClick={() => updateWorkExperienceDetail(experience.id)} title="More Details" className="details-link"><i className="bi bi-link-45deg"></i></a>
                </div>
            </div>
        </div>
    )

    let [workDetails] = useState(experienceList);

    return (
        <>
            <section id="portfolio" className="portfolio section light-background">
                <div className="container section-title" data-aos="fade-up">
                    <h2>Work Experiences</h2>
                    <p>I have done both front-end and back-end development across different business domains using different technology stacks. Below are some of my more
                        noteworthy contributions I have done over the years as part of my previous employment endeavors.</p>
                </div>
                <div className="container">
                    <div className="isotope-layout" data-default-filter="*" data-layout="masonry" data-sort="original-order">
                        <div className="row gy-4 isotope-container" data-aos="fade-up" data-aos-delay="200">
                            {workDetails}
                        </div>
                    </div>
                </div>
            </section>
            <section id="portfolio-details" className="portfolio-details section" style={{display: `${showExperienceDetailPanel}`}}>
                <a href="#portfolio">
                <img src={closeimg} className="img-fluid" style={{margin: '0px 0px 25px 0px', width: '100px', height: '50px', marginLeft: 'auto', marginRight: 'auto', display: 'block'}} onClick={() => updateWorkExperienceDetail(0)} />
                </a>

                <div className="container" data-aos="fade-up" data-aos-delay="100">
                    <div className="row gy-4">
                        <div className="col-lg-8">
                            <div className="portfolio-details-slider swiper init-swiper">
                                <div className="swiper-wrapper align-items-center">
                                    <div className="swiper-slide">
                                        <img src={workDetail.image} alt=""/>
                                    </div>
                                </div>
                                <div className="swiper-pagination"></div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="portfolio-info" data-aos="fade-up" data-aos-delay="200">
                                <h3>Project information</h3>
                                <ul>
                                    <li><strong>Technologies</strong>: {workDetail.technologies}</li>
                                    <li><strong>Employer</strong>: {workDetail.employer}</li>
                                    <li><strong>Tenure</strong>: {workDetail.tenure}</li>
                                    <li><strong>URL</strong>: {workDetail.url}</li>
                                </ul>
                            </div>
                            <div className="portfolio-description" data-aos="fade-up" data-aos-delay="300">
                                <p>{workDetail.summary}</p>
                                <p>{workDetail.summary2}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default WorkExperiences;