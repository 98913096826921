export const workexperiencesdetails = [
  {
    "id": 1,
    "image": "../assets/img/portfolio/app-1.png",
    "technologies": "Web design (HTML, CSS), Backend Development (Java, JSP, Servlets), Database (Access JDBC/ODBC)",
    "employer": "Cox Interactive Media",
    "tenure": "2000 - 2001",
    "url": "www.LAinsider.com",
    "summary": "I was hired as an intern to do HTML development work and data entry work for the city site LAInsider.com under the Cox Interactive Media brand. Additionally, I single-handedly implemented a Java frontend-backend solution using Java-based JavaServerPages/Servlets over Tomcat to provide a web application that can better assist in how we managed the events calendar entries."
  },
  {
    "id": 2,
    "image": "../assets/img/portfolio/app-2.png",
    "technologies": "Web design (HTML, CSS, JavaScript), Backend Development (C#.net, BASIC/Windows Batch), Database (IBM DB2)",
    "employer": "Unified Grocers",
    "tenure": "2008 - 2011",
    "url": "www.unifiedgrocers.com",
    "summary": "I was hired to be an intern at Unified Grocers as a Helpdesk Technician which gave me the opportunity to attend and complete my undergraduate studies. While there, I was also given the opportunity to do development on a C#-based web application used for inventory management. This granted me an opportunity to work with C#/.net Framework, JavaScript, and IBM DB2 databases. Lastly, I also wrote an installer in BASIC that addressed a helpdesk hardship that the team encountered when supporting an antiquated fleet management software that needed to be installed on workstations."
  },
  {
    "id": 3,
    "image": "../assets/img/portfolio/app-3.png",
    "technologies": "Web design (HTML, CSS, JavaScript/JQuery, JSF), Backend Development (Java/Spring), Database (MS SQL), AWS",
    "employer": "Dun and Bradstreet Credibility Corp., Dun and Bradstreet",
    "tenure": "2012 - 2017",
    "url": "www.dandb.com",
    "summary": "My first significant foray into software development post-undergraduate studies. During my time at Dun and Bradstreet, I developed several web applications for both customer-facing and business-facing entities using the Java Spring Framework stack. Additionally, I was ultimately promoted to Engineering Manager, which allowed me the honor to lead both onshore and offshore teams on various projects."
  },
  {
    "id": 4,
    "image": "../assets/img/portfolio/app-4.png",
    "technologies": "Backend Development (Java/Spring), Database (MySQL), AWS",
    "employer": "ReachLocal, Gannett, Inc",
    "tenure": "2017 - 2018",
    "url": "www.reachlocal.com",
    "summary": "During my time at ReachLocal (acquired by Gannett, Inc), I focused on building upon existing backend services to support the finance infrastructure, which included credit card transactions and tokenization across different markets both in the United States and Internationally. I ultimately was promoted to Director of Software Engineering, which gave me an opportunity to envision and implement solutions that will move the engineering organization forward."
  },
  {
    "id": 5,
    "image": "../assets/img/portfolio/app-5.png",
    "technologies": "Backend Development (Java/Spring), Clover DX, Angular, Database (MySQL)",
    "employer": "Verifi, Visa, Inc",
    "tenure": "2018 - 2024",
    "url": "www.verifi.com",
    "summary": "My time at Visa provided me with extensive opportunities to lead under pressure. I led two different product teams to deliver important deliverables to our clients forging tech integrations with noteworthy third-party business partners that tapped into our business services. When Verifi was acquired by Visa, I was given the opportunity to lead the security remediation efforts to address a substantial number of security findings in our legacy platform in order to remain in Visa compliance while still leading my existing product team. After our migration concluded, my team and I refocused our efforts on delivering industry-leading solutions in chargeback representment working under the new Visa development stack."
  },
  {
    "id": 6,
      "image": "../assets/img/portfolio/app-6.png",
      "technologies": "Frontend (Bootstrap with React), Backend Development (Java/Spring), Database (MySQL), AWS",
      "employer": "Me",
      "tenure": "2024",
      "url": "www.bravaldi.com",
      "summary": "For a long time, I've been wanting to put together a web platform that not only showcases my achievements and interests, but I can use as springboard for ideas that I want to implement in the future. Recently, I have been given the opportunity to put this together which allowed me to showcase my technical skills in various areas to my satisfaction. This solution has been implemented in React (FE), Java/Spring (BE), MySQL (DB), making use of various AWS services (EC2, ECS, ECR, EFS, VPC) and IaC managed via Terraform.",
      "summary2": "Why bravaldi? It is a portmanteau between my last name (\"Bravo\") and my favorite Baroque composer's last name, Vivaldi!"
  }
];